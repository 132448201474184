export const BLOCK_DIFF_ALLOWANCE = 60;
export const actionOptions = [
  {
    label: "Committed",
    value: "Committed",
  },
  {
    label: "Revealed",
    value: "Revealed",
  },
  {
    label: "Proposed",
    value: "Proposed",
  },
  {
    label: "Dispute",
    value: "DisputeBiggestStakeProposed",
  },
  {
    label: "BlockConfirmed",
    value: "BlockConfirmed",
  },
  {
    label: "BlockReward",
    value: "BlockReward",
  },
  {
    label: "InactivityPenalty",
    value: "InactivityPenalty",
  },
  {
    label: "AgeChange",
    value: "AgeChange",
  },
  {
    label: "Slashed",
    value: "Slashed",
  },
  {
    label: "Snitch",
    value: "Snitch",
  },
  {
    label: "Delegated",
    value: "Delegated",
  },
  {
    label: "Staked",
    value: "Staked",
  },

  {
    label: "Unstaked",
    value: "Unstaked",
  },
  {
    label: "Withdrew",
    value: "Withdrew",
  },
  {
    label: "CollectionCreated",
    value: "CollectionCreated",
  },
  {
    label: "CollectionUpdated",
    value: "CollectionUpdated",
  },
  {
    label: "JobCreated",
    value: "JobCreated",
  },
  {
    label: "JobUpdated",
    value: "JobUpdated",
  },
  {
    label: "RedeemBounty",
    value: "RedeemBounty",
  },
  {
    label: "GiveSorted",
    value: "GiveSorted",
  },
  {
    label: "ResetUnstakeLock",
    value: "ResetUnstakeLock",
  },
];
export const BlockOptions = [
  {
    label: "BlockConfirmed",
    value: "BlockConfirmed",
  },
  {
    label: "Proposed",
    value: "Proposed",
  },
  {
    label: "Dispute",
    value: "DisputeBiggestStakeProposed",
  },
  {
    label: "GiveSorted",
    value: "GiveSorted",
  },
];
export const VoteOptions = [
  {
    label: "Committed",
    value: "Committed",
  },
  {
    label: "Revealed",
    value: "Revealed",
  },
  {
    label: "Snitch",
    value: "Snitch",
  },
];
export const StakerActionOptions = [
  {
    label: "Delegated",
    value: "Delegated",
  },
  {
    label: "Staked",
    value: "Staked",
  },
  {
    label: "Unstaked",
    value: "Unstaked",
  },
  {
    label: "Withdrew",
    value: "Withdrew",
  },
  {
    label: "Slashed",
    value: "Slashed",
  },
  {
    label: "WithdrawInitiated",
    value: "WithdrawInitiated",
  },
  {
    label: "RedeemBounty",
    value: "RedeemBounty",
  },
  {
    label: "Commission",
    value: "StakerRewardChange",
  },
  {
    label: "BlockReward",
    value: "BlockReward",
  },
  {
    label: "InactivityPenalty",
    value: "InactivityPenalty",
  },
];
const chainId = localStorage.getItem("chainId");
export const blockExplorerUrl =
  chainId == "11155420" || chainId == "undefined"
    ? process.env.REACT_APP_RAZOR_BLOCK_EXPLORER_URL
    : process.env.REACT_APP_EUROPA_BLOCK_EXPLORER_URL;
const domain = window.location.hostname;
console.log(domain, domain !== "staging");
export const isProduction = domain === "razorscan.io";
export const BASE_DENOMINATOR = 10000000;

export const CMCRazorTokenURL =
  "https://coinmarketcap.com/currencies/razor-network/";

export const RolesObj = {
  "0x18797bc7973e1dadee1895be2f1003818e30eae3b0e7a01eb9b2e66f3ea2771f":
    "BLOCK_CONFIRMER_ROLE",

  // keccak256("STAKE_MODIFIER_ROLE")
  "0xdbaaaff2c3744aa215ebd99971829e1c1b728703a0bf252f96685d29011fc804":
    "STAKE_MODIFIER_ROLE",

  // keccak256("REWARD_MODIFIER_ROLE")
  "0xcabcaf259dd9a27f23bd8a92bacd65983c2ebf027c853f89f941715905271a8d":
    "REWARD_MODIFIER_ROLE",

  // keccak256("COLLECTION_MODIFIER_ROLE")
  "0xa3a75e7cd2b78fcc3ae2046ab93bfa4ac0b87ed7ea56646a312cbcb73eabd294":
    "COLLECTION_MODIFIER_ROLE",

  // keccak256("VOTE_MODIFIER_ROLE")
  "0xca0fffcc0404933256f3ec63d47233fbb05be25fc0eacc2cfb1a2853993fbbe5":
    "VOTE_MODIFIER_ROLE",

  // keccak256("DELEGATOR_MODIFIER_ROLE")
  "0x6b7da7a33355c6e035439beb2ac6a052f1558db73f08690b1c9ef5a4e8389597":
    "DELEGATOR_MODIFIER_ROLE",

  // keccak256("REGISTRY_MODIFIER_ROLE")
  "0xca51085219bef34771da292cb24ee4fcf0ae6bdba1a62c17d1fb7d58be802883":
    "REGISTRY_MODIFIER_ROLE",

  // keccak256("SECRETS_MODIFIER_ROLE")
  "0x46aaf8a125792dfff6db03d74f94fe1acaf55c8cab22f65297c15809c364465c":
    "SECRETS_MODIFIER_ROLE",

  // keccak256("PAUSE_ROLE")
  "0x139c2898040ef16910dc9f44dc697df79363da767d8bc92f2e310312b816e46d":
    "PAUSE_ROLE",

  // keccak256("GOVERNANCE_ROLE")
  "0x71840dc4906352362b0cdaf79870196c8e42acafade72d5d5a6d59291253ceb1":
    "GOVERNANCE_ROLE",

  // keccak256("STOKEN_ROLE")
  "0xce3e6c780f179d7a08d28e380f7be9c36d990f56515174f8adb6287c543e30dc":
    "STOKEN_ROLE",

  // keccak256("SALT_MODIFIER_ROLE")
  "0xf31dda80d37c96a1a0852ace387dda52a75487d7d4eb74895e749ede3e0987b4":
    "SALT_MODIFIER_ROLE",

  // keccak256("DEPTH_MODIFIER_ROLE)")
  "0x91f5d9ea80c4d04985e669bc72870410b28b57afdf61c0d50d377766d86a3748":
    "DEPTH_MODIFIER_ROLE",

  // keccak256("ESCAPE_HATCH_ROLE")
  "0x518d8c39717318f051dfb836a4ebe5b3c34aa2cb7fce26c21a89745422ba8043":
    "ESCAPE_HATCH_ROLE",

  // keccak256("OCCURRENCE_MODIFIER_ROLE")
  "0x35ed6c1cb451e31b9dd4f1d325602da07694e1747843e6b55ab1527fd8835fb5":
    "OCCURRENCE_MODIFIER_ROLE",

  // keccak256("RESET_DATABOND_ROLE")
  "0x3e99a7fb3946972656cbde0e63ef530dd7750472272e07c65aa9f473a99f5c5d":
    "RESET_DATABOND_ROLE",

  // keccak256("COLLECTION_CONFIRMER_ROLE")
  "0xa1d2ec18e7ea6241ef0566da3d2bc59cc059592990e56680abdc7031155a0c28":
    "COLLECTION_CONFIRMER_ROLE",

  "0x0000000000000000000000000000000000000000000000000000000000000000":
    "DEFAULT_ADMIN_ROLE",

  "0x704c992d358ec8f6051d88e5bd9f92457afedcbc3e2d110fcd019b5eda48e52e":
    "GOVERNER_ROLE",
};

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const addressRolesObj = {
  // signer[0].address admin
  "0xeaf0015883f78257292193adc3fba6c3565b94e4": [
    "0x0000000000000000000000000000000000000000000000000000000000000000",
    "0x518d8c39717318f051dfb836a4ebe5b3c34aa2cb7fce26c21a89745422ba8043",
    "0xa3a75e7cd2b78fcc3ae2046ab93bfa4ac0b87ed7ea56646a312cbcb73eabd294",
    "0x704c992d358ec8f6051d88e5bd9f92457afedcbc3e2d110fcd019b5eda48e52e",
  ],
  "0x916dee7fea61bb90b8edbf030b6cdc3f070af8a2": [
    "0x139c2898040ef16910dc9f44dc697df79363da767d8bc92f2e310312b816e46d",
  ],
  // new admin
  "0xd7346285aee49750497f19a3a378cf365649788f": [
    "0x0000000000000000000000000000000000000000000000000000000000000000",
  ],
  // Governance Address
  "0x6a4f5803c44d4ba6189d24718863a85783b92c57": [
    "0x71840dc4906352362b0cdaf79870196c8e42acafade72d5d5a6d59291253ceb1",
  ],
  // Collection Manager
  "0x4a6d18e64af3dc24daa7a0abba00670476395a2c": [
    "0x91f5d9ea80c4d04985e669bc72870410b28b57afdf61c0d50d377766d86a3748",
  ],
  // Vote Manager
  "0x1fa41fda5a5169deb7eb5696757d5770ce57bf22": [
    "0x18797bc7973e1dadee1895be2f1003818e30eae3b0e7a01eb9b2e66f3ea2771f",
    "0xdbaaaff2c3744aa215ebd99971829e1c1b728703a0bf252f96685d29011fc804",
    "0xcabcaf259dd9a27f23bd8a92bacd65983c2ebf027c853f89f941715905271a8d",
  ],
  // Block Manager
  "0x1f323d8c170bc526fba6e30653dfdd10064ff5a0": [
    "0xdbaaaff2c3744aa215ebd99971829e1c1b728703a0bf252f96685d29011fc804",
    "0xcabcaf259dd9a27f23bd8a92bacd65983c2ebf027c853f89f941715905271a8d",
    "0xf31dda80d37c96a1a0852ace387dda52a75487d7d4eb74895e749ede3e0987b4",
    "0xca51085219bef34771da292cb24ee4fcf0ae6bdba1a62c17d1fb7d58be802883",
    "0x46aaf8a125792dfff6db03d74f94fe1acaf55c8cab22f65297c15809c364465c",
  ],
  // Reward Manager
  "0x65ebb295f9b5871f08c57eef0a44052aea9f0881": [
    "0xdbaaaff2c3744aa215ebd99971829e1c1b728703a0bf252f96685d29011fc804",
  ],
  // Stake Manager
  "0x83f3022c2bf36423194ebaa9b66cedd0e3db8b06": [
    "0xcabcaf259dd9a27f23bd8a92bacd65983c2ebf027c853f89f941715905271a8d",
  ],
};

export const contractNamesAddressMapping = {
  "0x6a4f5803c44D4BA6189D24718863a85783B92c57": "Governance",
  "0x1F323d8c170bC526fbA6E30653dfdD10064FF5A0": "BlockManager",
  "0x4A6d18E64AF3dC24dAA7a0abbA00670476395A2c": "CollectionManager",
  "0x83F3022c2Bf36423194EBAA9b66CeDD0e3dB8b06": "StakeManager",
  "0x65eBb295F9B5871F08c57eEF0a44052aEA9f0881": "RewardManager",
  "0x1fA41fda5a5169deB7EB5696757D5770ce57Bf22": "VoteManager",
  "0x4535E7486c48Df8e1121be2A31b74aBb2b0a5B8b": "Delegator",
  "0xAD1259343CF69a916a1A9AAf6250d2Db67606A5D": "RAZOR",
  "0x101017d94A27062f7d176d3b8d67b0C93faff40f": "StakedTokenFactory",
  "0x49Dd8FfDB80b3480C16D8769EA47cdBb777933e2": "RandomNoManager",
};

export const mainnetAddresses = {
  Governance: "0x6a4f5803c44D4BA6189D24718863a85783B92c57",
  BlockManager: "0x1F323d8c170bC526fbA6E30653dfdD10064FF5A0",
  CollectionManager: "0x4A6d18E64AF3dC24dAA7a0abbA00670476395A2c",
  StakeManager: "0x83F3022c2Bf36423194EBAA9b66CeDD0e3dB8b06",
  RewardManager: "0x65eBb295F9B5871F08c57eEF0a44052aEA9f0881",
  VoteManager: "0x1fA41fda5a5169deB7EB5696757D5770ce57Bf22",
  Delegator: "0x4535E7486c48Df8e1121be2A31b74aBb2b0a5B8b",
  RAZOR: "0xAD1259343CF69a916a1A9AAf6250d2Db67606A5D",
  StakedTokenFactory: "0x101017d94A27062f7d176d3b8d67b0C93faff40f",
  RandomNoManager: "0x49Dd8FfDB80b3480C16D8769EA47cdBb777933e2",
};

export const EPOCHS_IN_WEEK = 504;
const DEPLOYER_ADDRESS = "0xeaf0015883f78257292193adc3fba6c3565b94e4";

const {
  CollectionManager,
  Governance,
  BlockManager,
  StakeManager,
  RewardManager,
  VoteManager,
  Delegator,
  RandomNoManager,
} = mainnetAddresses;

const DEFAULT_ADMIN_ROLE =
  "0x0000000000000000000000000000000000000000000000000000000000000000";
const GOVERNANCE_ROLE =
  "0x71840dc4906352362b0cdaf79870196c8e42acafade72d5d5a6d59291253ceb1";
const REGISTRY_MODIFIER_ROLE =
  "0xca51085219bef34771da292cb24ee4fcf0ae6bdba1a62c17d1fb7d58be802883";
const COLLECTION_MODIFIER_ROLE =
  "0xa3a75e7cd2b78fcc3ae2046ab93bfa4ac0b87ed7ea56646a312cbcb73eabd294";
const STAKE_MODIFIER_ROLE =
  "0xdbaaaff2c3744aa215ebd99971829e1c1b728703a0bf252f96685d29011fc804";
const PAUSE_ROLE =
  "0x139c2898040ef16910dc9f44dc697df79363da767d8bc92f2e310312b816e46d";
const ESCAPE_HATCH_ROLE =
  "0x518d8c39717318f051dfb836a4ebe5b3c34aa2cb7fce26c21a89745422ba8043";
const BLOCK_CONFIRMER_ROLE =
  "0x18797bc7973e1dadee1895be2f1003818e30eae3b0e7a01eb9b2e66f3ea2771f";
const REWARD_MODIFIER_ROLE =
  "0xcabcaf259dd9a27f23bd8a92bacd65983c2ebf027c853f89f941715905271a8d";
const SALT_MODIFIER_ROLE =
  "0xf31dda80d37c96a1a0852ace387dda52a75487d7d4eb74895e749ede3e0987b4";
const DEPTH_MODIFIER_ROLE =
  "0x91f5d9ea80c4d04985e669bc72870410b28b57afdf61c0d50d377766d86a3748";
const GOVERNER_ROLE =
  "0x704c992d358ec8f6051d88e5bd9f92457afedcbc3e2d110fcd019b5eda48e52e";

// CONTRACT_ADDRESS -> ROLE_HASH -> [ADDRESS]
export const roles = {
  [CollectionManager.toLowerCase()]: {
    [GOVERNANCE_ROLE.toLowerCase()]: [Governance.toLowerCase()],
    [REGISTRY_MODIFIER_ROLE]: [BlockManager.toLowerCase()],
    [COLLECTION_MODIFIER_ROLE]: [DEPLOYER_ADDRESS.toLowerCase()],
    [DEFAULT_ADMIN_ROLE]: [DEPLOYER_ADDRESS.toLowerCase()],
  },
  [BlockManager.toLowerCase()]: {
    [GOVERNANCE_ROLE]: [Governance.toLowerCase()],
    [BLOCK_CONFIRMER_ROLE]: [VoteManager.toLowerCase()],
    [DEFAULT_ADMIN_ROLE]: [DEPLOYER_ADDRESS.toLowerCase()],
  },
  [RewardManager.toLowerCase()]: {
    [GOVERNANCE_ROLE]: [Governance.toLowerCase()],
    [REWARD_MODIFIER_ROLE]: [
      BlockManager.toLowerCase(),
      VoteManager.toLowerCase(),
      StakeManager.toLowerCase(),
    ],
    [DEFAULT_ADMIN_ROLE]: [DEPLOYER_ADDRESS.toLowerCase()],
  },
  [VoteManager.toLowerCase()]: {
    [GOVERNANCE_ROLE]: [Governance.toLowerCase()],
    [SALT_MODIFIER_ROLE]: [BlockManager.toLowerCase()],
    [DEPTH_MODIFIER_ROLE]: [CollectionManager.toLowerCase()],
    [DEFAULT_ADMIN_ROLE]: [DEPLOYER_ADDRESS.toLowerCase()],
  },
  [StakeManager.toLowerCase()]: {
    [GOVERNANCE_ROLE]: [Governance.toLowerCase()],
    [STAKE_MODIFIER_ROLE]: [
      RewardManager.toLowerCase(),
      BlockManager.toLowerCase(),
      VoteManager.toLowerCase(),
    ],
    [PAUSE_ROLE]: [
      DEPLOYER_ADDRESS.toLowerCase(),
      "0x916dee7fea61bb90b8edbf030b6cdc3f070af8a2",
    ],
    [DEFAULT_ADMIN_ROLE.toLowerCase()]: [DEPLOYER_ADDRESS.toLowerCase()],
    [ESCAPE_HATCH_ROLE]: [DEPLOYER_ADDRESS.toLowerCase()],
  },
  [Delegator.toLowerCase()]: {
    [GOVERNANCE_ROLE]: [Governance.toLowerCase()],
    [DEFAULT_ADMIN_ROLE]: [DEPLOYER_ADDRESS.toLowerCase()],
  },
  [RandomNoManager.toLowerCase()]: {
    [GOVERNANCE_ROLE]: [Governance.toLowerCase()],
    [DEFAULT_ADMIN_ROLE]: [DEPLOYER_ADDRESS.toLowerCase()],
  },
  [Governance.toLowerCase()]: {
    [DEFAULT_ADMIN_ROLE]: [DEPLOYER_ADDRESS.toLowerCase()],
    [GOVERNER_ROLE]: [DEPLOYER_ADDRESS.toLowerCase()],
  },
};

// Addresses from https://www.treasury.gov/ofac/downloads/sdnlist.txt
export const sanctionedAddresses = [
  "0x6acdfba02d390b97ac2b2d42a63e85293bcc160e",
  "0x5512d943ed1f7c8a43f3435c85f7ab68b30121b0",
  "0x1da5821544e25c636c1417ba96ade4cf6d2f9b5a",
  "0x72a5843cc08275c8171e582972aa4fda8c397b2a",
  "0xa0e1c89ef1a489c9c7de96311ed5ce5d32c20e4b",
  "0xf7b31119c2682c88d88d455dbb9d5932c65cf1be",
  "0x08723392ed15743cc38513c4925f5e6be5c17243",
  "0xfec8a60023265364d066a1212fde3930f6ae8da7",
  "0x3ad9db589d201a710ed237c829c7860ba86510fc",
  "0x9f4cda013e354b8fc285bf4b9a60460cee7f7ea9",
  "0x2f389ce8bd8ff92de3402ffce4691d17fc4f6535",
  "0xe7aa314c77f4233c18c6cc84384a9247c0cf367b",
  "0x19aa5fe80d33a56d56c78e82ea5e50e5d80b4dff",
  "0x8589427373d6d84e98730d7795d8f6f8731fda16",
  "0xdd4c48c0b24039969fc16d1cdf626eab821d3384",
  "0xd96f2b1c14db8458374d9aca76e26c3d18364307",
  "0xd4b88df4d29f5cedd6857912842cff3b20c8cfa3",
  "0xa160cdab225685da1d56aa342ad8841c3b53f291",
  "0xf60dd140cff0706bae9cd734ac3ae76ad9ebc32a",
  "0xba214c1c1928a32bffe790263e38b4af9bfcd659",
  "0x527653ea119f3e6a1f5bd18fbf4714081d7b31ce",
  "0xd691f27f38b395864ea86cfc7253969b409c362d",
  "0x1356c899d8c9467c7f71c195612f8a395abf2f0a",
  "0x169ad27a470d064dede56a2d3ff727986b15d52b",
  "0xf67721a2d8f736e75a49fdd7fad2e31d8676542a",
  "0x905b63fff465b9ffbf41dea908ceb12478ec7601",
  "0x94a1b5cdb22c43faab4abeb5c74999895464ddaf",
  "0x12d66f87a04a9e220743712ce6d9bb1b5616b8fc",
  "0x23773e65ed146a459791799d01336db287f25334",
  "0x610b717796ad172b316836ac95a2ffad065ceab4",
  "0xbb93e510bbcd0b7beb5a853875f9ec60275cf498",
  "0x03893a7c7463ae47d46bc7f091665f1893656003",
  "0x58e8dcc13be9780fc42e8723d8ead4cf46943df2",
  "0x53b6936513e738f44fb50d2b9476730c0ab3bfc1",
  "0x098b716b8aaf21512996dc57eb0615e2383e2f96",
  "0x3cffd56b47b7b41c56258d9c7731abadc360e073",
  "0x35fb6f6db4fb05e6a4ce86f2c93691425626d4b1",
  "0x3e37627deaa754090fbfbb8bd226c1ce66d255e9",
  "0x722122dF12D4e14e13Ac3b6895a86e84145b6967",
  "0xd90e2f925DA726b50C4Ed8D0Fb90Ad053324F31b",
  "0x4736dCf1b7A3d580672CcE6E7c65cd5cc9cFBa9D",
  "0x910Cbd523D972eb0a6f4cAe4618aD62622b39DbF",
  "0xFD8610d20aA15b7B2E3Be39B396a1bC3516c7144",
  "0x22aaA7720ddd5388A3c0A3333430953C68f1849b",
  "0xb1C8094B234DcE6e03f10a5b673c1d8C69739A00",
  "0xaEaaC358560e11f52454D997AAFF2c5731B6f8a6",
  "0xA60C772958a3eD56c1F15dD055bA37AC8e523a0D",
  "0x0836222F2B2B24A3F36f98668Ed8F0B38D1a872f",
  "0x9AD122c22B14202B4490eDAf288FDb3C7cb3ff5E",
  "0x07687e702b410Fa43f4cB4Af7FA097918ffD2730",
  "0xb541fc07bC7619fD4062A54d96268525cBC6FfEF",
  "0x47CE0C6eD5B0Ce3d3A51fdb1C52DC66a7c3c2936",
  "0xD21be7248e0197Ee08E0c20D4a96DEBdaC3D20Af",
  "0x178169B423a011fff22B9e3F3abeA13414dDD0F1",
  "0x2717c5e28cf931547B621a5dddb772Ab6A35B701",
  "0xCa0840578f57fE71599D29375e16783424023357",
  "0x7Db418b5D567A4e0E8c59Ad71BE1FcE48f3E6107",
  "0x7F19720A857F834887FC9A7bC0a0fBe7Fc7f8102",
  "0xA7e5d5A720f06526557c513402f2e6B5fA20b008",
  "0x7F367cC41522cE07553e823bf3be79A889DEbe1B",
  "0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b",
  "0x901bb9583b24D97e995513C6778dc6888AB6870e",
  "0x8576aCC5C05D6Ce88f4e49bf65BdF0C62F91353C",
  "0xC8a65Fadf0e0dDAf421F28FEAb69Bf6E2E589963",
  "0x308eD4B7b49797e1A98D3818bFF6fe5385410370",
  "0x3CBdeD43EFdAf0FC77b9C55F6fC9988fCC9b757d",
  "0x67d40EE1A85bf4a4Bb7Ffae16De985e8427B6b45",
  "0x6F1cA141A28907F78Ebaa64fb83A9088b02A8352",
  "0x48549a34ae37b12f6a30566245176994e17c6b4a",
  "0xC455f7fd3e0e12afd51fba5c106909934D8A0e4a",
  "0x629e7Da20197a5429d30da36E77d06CdF796b71A",
  "0x7FF9cFad3877F21d41Da833E2F775dB0569eE3D9",
];

export const consumeDataContracts = {
  "Calypso Mainnet (honorable-steel-rasalhague)": `// SPDX-License-Identifier: MIT
        pragma solidity ^0.8.0;
        
        interface ITransparentForwarder {
            /**
             * @dev using the hash of collection name, clients can query the result of that collection
             * @param _name bytes32 hash of the collection name
             * @return result of the collection and its power
             */
            function getResult(bytes32 _name) external payable returns (uint256, int8);
        }
        
        contract DataFeed {
            ITransparentForwarder public transparentForwarder;
        
            constructor() {
                transparentForwarder = ITransparentForwarder(0xEb9324f0d17e4dEa7371f6dddf361D9bB453BEb9);
            }
        
            /// @notice fetch collection result by name
            /// @param _name bytes32 hash of the collection name
            /// @return result of the collection and its power
            /// @return power
            function getResult(bytes32 name) public payable returns (uint256, int8) {
                (uint256 result, int8 power) = transparentForwarder.getResult{
                    value: msg.value
                }(name);
                return (result, power);
            }
        }
        `,

  "Nebula Mainnet (green-giddy-denebola)": `// SPDX-License-Identifier: MIT
        pragma solidity ^0.8.0;
        
        interface ITransparentForwarder {
            /**
             * @dev using the hash of collection name, clients can query the result of that collection
             * @param _name bytes32 hash of the collection name
             * @return result of the collection and its power
             */
            function getResult(bytes32 _name) external payable returns (uint256, int8);
        }
        
        contract DataFeed {
            ITransparentForwarder public transparentForwarder;
        
            constructor() {
                transparentForwarder = ITransparentForwarder(0xEb9324f0d17e4dEa7371f6dddf361D9bB453BEb9);
            }
        
            /// @notice fetch collection result by name
            /// @param _name bytes32 hash of the collection name
            /// @return result of the collection and its power
            /// @return power
            function getResult(bytes32 name) public payable returns (uint256, int8) {
                (uint256 result, int8 power) = transparentForwarder.getResult{
                    value: msg.value
                }(name);
                return (result, power);
            }
        }
        `,

  "Europa Mainnet (elated-tan-skat)": `// SPDX-License-Identifier: MIT
        pragma solidity ^0.8.0;
        
        interface ITransparentForwarder {
            /**
             * @dev using the hash of collection name, clients can query the result of that collection
             * @param _name bytes32 hash of the collection name
             * @return result of the collection and its power
             */
            function getResult(bytes32 _name) external payable returns (uint256, int8);
        }
        
        contract DataFeed {
            ITransparentForwarder public transparentForwarder;
        
            constructor() {
                transparentForwarder = ITransparentForwarder(0xEb9324f0d17e4dEa7371f6dddf361D9bB453BEb9);
            }
        
            /// @notice fetch collection result by name
            /// @param _name bytes32 hash of the collection name
            /// @return result of the collection and its power
            /// @return power
            function getResult(bytes32 name) public payable returns (uint256, int8) {
                (uint256 result, int8 power) = transparentForwarder.getResult{
                    value: msg.value
                }(name);
                return (result, power);
            }
        }
        `,

  "Calypso Testnet (staging-utter-unripe-menkar)": `// SPDX-License-Identifier: MIT
        pragma solidity ^0.8.0;
        
        interface ITransparentForwarder {
            /**
             * @dev using the hash of collection name, clients can query the result of that collection
             * @param _name bytes32 hash of the collection name
             * @return result of the collection and its power
             */
            function getResult(bytes32 _name) external payable returns (uint256, int8);
        }
        
        contract DataFeed {
            ITransparentForwarder public transparentForwarder;
        
            constructor() {
                transparentForwarder = ITransparentForwarder(0xbF5c5AD799b2245BA36562BebfcbAbc5D508Eb84);
            }
        
            /// @notice fetch collection result by name
            /// @param _name bytes32 hash of the collection name
            /// @return result of the collection and its power
            /// @return power
            function getResult(bytes32 name) public payable returns (uint256, int8) {
                (uint256 result, int8 power) = transparentForwarder.getResult{
                    value: msg.value
                }(name);
                return (result, power);
            }
        }
        `,
  "Europa Testnet (staging-legal-crazy-castor)": `// SPDX-License-Identifier: MIT
        pragma solidity ^0.8.0;
        
        interface ITransparentForwarder {
            /**
             * @dev using the hash of collection name, clients can query the result of that collection
             * @param _name bytes32 hash of the collection name
             * @return result of the collection and its power
             */
            function getResult(bytes32 _name) external payable returns (uint256, int8);
        }
        
        contract DataFeed {
            ITransparentForwarder public transparentForwarder;
        
            constructor() {
                transparentForwarder = ITransparentForwarder(0xbF5c5AD799b2245BA36562BebfcbAbc5D508Eb84);
            }
        
            /// @notice fetch collection result by name
            /// @param _name bytes32 hash of the collection name
            /// @return result of the collection and its power
            /// @return power
            function getResult(bytes32 name) public payable returns (uint256, int8) {
                (uint256 result, int8 power) = transparentForwarder.getResult{
                    value: msg.value
                }(name);
                return (result, power);
            }
        }
      
        `,
  "Polygon Mumbai": `// SPDX-License-Identifier: MIT
        pragma solidity ^0.8.0;
        
        interface ITransparentForwarder {
            /**
             * @dev using the hash of collection name, clients can query the result of that collection
             * @param _name bytes32 hash of the collection name
             * @return result of the collection and its power
             */
            function getResult(bytes32 _name) external payable returns (uint256, int8);
        }
        
        contract DataFeed {
            ITransparentForwarder public transparentForwarder;
        
            constructor() {
                transparentForwarder = ITransparentForwarder(0x76a6AB56E27823B2175F11b0041c489bFdb13c88);
            }
        
            /// @notice fetch collection result by name
            /// @param _name bytes32 hash of the collection name
            /// @return result of the collection and its power
            /// @return power
            function getResult(bytes32 name) public payable returns (uint256, int8) {
                (uint256 result, int8 power) = transparentForwarder.getResult{
                    value: msg.value
                }(name);
                return (result, power);
            }
        }
          `,
  "Moonbase Alpha": `// SPDX-License-Identifier: MIT
        pragma solidity ^0.8.0;
        
        interface ITransparentForwarder {
            /**
             * @dev using the hash of collection name, clients can query the result of that collection
             * @param _name bytes32 hash of the collection name
             * @return result of the collection and its power
             */
            function getResult(bytes32 _name) external payable returns (uint256, int8);
        }
        
        contract DataFeed {
            ITransparentForwarder public transparentForwarder;
        
            constructor() {
                transparentForwarder = ITransparentForwarder(0x03bD73afB6d3C5B86578010C099E5474aF1aABeF);
            }
        
            /// @notice fetch collection result by name
            /// @param _name bytes32 hash of the collection name
            /// @return result of the collection and its power
            /// @return power
            function getResult(bytes32 name) public payable returns (uint256, int8) {
                (uint256 result, int8 power) = transparentForwarder.getResult{
                    value: msg.value
                }(name);
                return (result, power);
            }
        }
          `,
  "zkSync2 Testnet": `// SPDX-License-Identifier: MIT
        pragma solidity ^0.8.0;
        
        interface ITransparentForwarder {
            /**
             * @dev using the hash of collection name, clients can query the result of that collection
             * @param _name bytes32 hash of the collection name
             * @return result of the collection and its power
             */
            function getResult(bytes32 _name) external payable returns (uint256, int8);
        }
        
        contract DataFeed {
            ITransparentForwarder public transparentForwarder;
        
            constructor() {
                transparentForwarder = ITransparentForwarder(0xE874C95b16964576f2dEa8277fA33214Fc9d73ed);
            }
        
            /// @notice fetch collection result by name
            /// @param _name bytes32 hash of the collection name
            /// @return result of the collection and its power
            /// @return power
            function getResult(bytes32 name) public payable returns (uint256, int8) {
                (uint256 result, int8 power) = transparentForwarder.getResult{
                    value: msg.value
                }(name);
                return (result, power);
            }
        }
          `,
  "XDC Testnet": `// SPDX-License-Identifier: MIT
          pragma solidity ^0.8.0;
          
          interface ITransparentForwarder {
              /**
               * @dev using the hash of collection name, clients can query the result of that collection
               * @param _name bytes32 hash of the collection name
               * @return result of the collection and its power
               */
              function getResult(bytes32 _name) external payable returns (uint256, int8);
          }
          
          contract DataFeed {
              ITransparentForwarder public transparentForwarder;
          
              constructor() {
                  transparentForwarder = ITransparentForwarder(0x53Df936a3594777185DF12Cd073d9033cfF348D6);
              }
          
              /// @notice fetch collection result by name
              /// @param _name bytes32 hash of the collection name
              /// @return result of the collection and its power
              /// @return power
              function getResult(bytes32 name) public payable returns (uint256, int8) {
                  (uint256 result, int8 power) = transparentForwarder.getResult{
                      value: msg.value
                  }(name);
                  return (result, power);
              }
          }
            `,
};

export const minSFuel = 0.003;

const sCalypsoMainnet = {
  name: "sCalypsoMainnet",
  id: 1564830818,
  rpcUrl: "https://mainnet.skalenodes.com/v1/honorable-steel-rasalhague",
  blockExplorer:
    "https://honorable-steel-rasalhague.explorer.mainnet.skalenodes.com/",
};

const sNebulaMainnet = {
  name: "sNebulaMainnet",
  id: 1482601649,
  rpcUrl: "https://mainnet.skalenodes.com/v1/green-giddy-denebola",
  blockExplorer:
    "https://green-giddy-denebola.explorer.mainnet.skalenodes.com/",
};

const sEuropaMainnet = {
  name: "sEuropaMainnet",
  id: 2046399126,
  rpcUrl: "https://mainnet.skalenodes.com/v1/elated-tan-skat",
  blockExplorer: "https://elated-tan-skat.explorer.mainnet.skalenodes.com/",
};

const sCalypsoTestnet = {
  name: "sCalypsoTestnet",
  id: 344106930,
  rpcUrl: "https://staging-v3.skalenodes.com/v1/staging-utter-unripe-menkar",
  blockExplorer:
    "https://staging-utter-unripe-menkar.explorer.staging-v3.skalenodes.com/",
};

const sEuropaTestnet = {
  name: "sEuropaTestnet",
  id: 476158412,
  rpcUrl: "https://staging-v3.skalenodes.com/v1/staging-legal-crazy-castor",
  blockExplorer:
    "https://staging-legal-crazy-castor.explorer.staging-v3.skalenodes.com/",
};

const polygonMumbai = {
  name: "polygonMumbai",
  id: 80001,
  rpcUrl:
    "https://polygon-mumbai.g.alchemy.com/v2/sX_UhTODHAwJKpSVWx-4tZpnIf4Q_nBe",
  blockExplorer: "https://mumbai.polygonscan.com/",
};

const moonbaseAlpha = {
  name: "moonbaseAlpha",
  id: 1287,
  rpcUrl: "https://rpc.api.moonbase.moonbeam.network",
  blockExplorer: "https://moonbase.moonscan.io/",
};

const zkSyncTestnet = {
  name: "zkSyncTestnet",
  id: 280,
  rpcUrl: "https://zksync2-testnet.zksync.dev",
  blockExplorer: "https://goerli.explorer.zksync.io/",
};

export const bscTestnet = {
  name: "BSCTestnet",
  id: 97,
  rpcUrl: "https://data-seed-prebsc-2-s1.binance.org:8545",
  blockExplorer: "https://testnet.bscscan.com/",
};

export const xdcTestnet = {
  name: "XDCTestnet",
  id: 51,
  rpcUrl: "https://erpc.apothem.network",
  blockExplorer: "https://explorer.apothem.network/",
};

export const supportedChains = [
  sCalypsoMainnet,
  sNebulaMainnet,
  sEuropaMainnet,
  sCalypsoTestnet,
  sEuropaTestnet,
  polygonMumbai,
  moonbaseAlpha,
  zkSyncTestnet,
  bscTestnet,
  xdcTestnet,
];

export const mainnetChains = [sCalypsoMainnet, sNebulaMainnet, sEuropaMainnet];

export const testnetChains = [
  sCalypsoTestnet,
  sEuropaTestnet,
  polygonMumbai,
  moonbaseAlpha,
  zkSyncTestnet,
  bscTestnet,
  xdcTestnet,
];

export const chainContracts = {
  [sCalypsoMainnet.id]: "0x528E94882C5e3AB519ce2eFfc819509c062B32a0",
  [sNebulaMainnet.id]: "0x528E94882C5e3AB519ce2eFfc819509c062B32a0",
  [sEuropaMainnet.id]: "0x528E94882C5e3AB519ce2eFfc819509c062B32a0",
  [zkSyncTestnet.id]: "0x3BB71B185cc0E45BfD1A13Aa5305B50a8De07b79",
  [polygonMumbai.id]: "0x2d4b87CEcaE6Eb01E6A027fC49Ce27504dBE1fD6",
  [moonbaseAlpha.id]: "0xa26232204879C4370806cA56F821b9fe97eAeF5B",
  [sCalypsoTestnet.id]: "0xFEb70E7Ad36b425b698184B3d5b1F856c8132a42",
  [sEuropaTestnet.id]: "0xFEb70E7Ad36b425b698184B3d5b1F856c8132a42",
  [bscTestnet.id]: "0xbF5c5AD799b2245BA36562BebfcbAbc5D508Eb84",
  [xdcTestnet.id]: "0x03bD73afB6d3C5B86578010C099E5474aF1aABeF",
};

export const opSepoliaTestnet = {
  id: 11155420,
  name: "OP Sepolia Testnet",
  network: "op-sepolia-testnet",
  iconBackground: "#fff",
  nativeCurrency: {
    decimals: 18,
    name: "ETH",
    symbol: "ETH",
  },
  rpcUrls: {
    public: {
      http: ["https://sepolia-optimism.etherscan.io/"],
    },
    default: {
      http: ["https://sepolia-optimism.etherscan.io/"],
    },
  },
  blockExplorers: {
    etherscan: {
      name: "Etherscan",
      url: "https://sepolia-optimism.etherscan.io/",
    },
    default: {
      name: "Etherscan",
      url: "https://sepolia-optimism.etherscan.io/",
    },
  },
};

export const skaleEuropaTestnet = {
  id: 1444673419,
  name: "Europa Testnet",
  network: "juicy-low-small-testnet",
  nativeCurrency: {
    name: "sFUEL",
    symbol: "sFUEL",
    decimals: 18,
  },
  rpcUrls: {
    public: {
      http: ["https://testnet.skalenodes.com/v1/juicy-low-small-testnet"],
    },
    default: {
      http: ["https://testnet.skalenodes.com/v1/juicy-low-small-testnet"],
    },
  },
  blockExplorers: {
    etherscan: {
      name: "Skalenodes",
      url: "https://juicy-low-small-testnet.explorer.testnet.skalenodes.com/",
    },
    default: {
      name: "Skalenodes",
      url: "https://juicy-low-small-testnet.explorer.testnet.skalenodes.com/",
    },
  },
  contracts: {},
  testnet: true,
};

export const calculateDomain = (lowest, highest) => {
  lowest = parseFloat(lowest);
  highest = parseFloat(highest);
  // Calculate the order of magnitude of the range
  const orderOfMagnitude = Math.floor(Math.log10(highest - lowest));

  // Determine the range increment based on the order of magnitude
  let increment;
  if (orderOfMagnitude >= 3) {
    // For large numbers (thousands and above)
    console.log("IN HERE HUge mag");
    increment = 1000;
  } else if (orderOfMagnitude >= 0) {
    // For numbers in range 1 - 999
    increment = Math.pow(10, orderOfMagnitude - 2) + 10;
    console.log("IN HERE MID mag", orderOfMagnitude, increment);
  } else {
    // For numbers less than 1
    increment = Math.pow(10, orderOfMagnitude);
  }

  // Calculate minDomain and maxDomain
  const min = lowest - increment;
  const max = highest + increment;

  return { min, max };
};

export const NETWORK_CHAIN_IDS = {
  europaTestnet: 1444673419,
  opSepoliaTestnet: 11155420,
};

export const GRAPHQL_ENDPOINTS = {
  europaTestnet:
    "https://indexer-graph-staging.razorscan.io/subgraphs/name/razor/razor-5min",
  opSepoliaTestnet:
    "https://api.studio.thegraph.com/query/66879/indexer-op-sepolia/v0.0.2",
};

export const RPC_URLS = {
  1444673419: "https://testnet.skalenodes.com/v1/juicy-low-small-testnet",
  11155420: "https://sepolia.optimism.io",
};

export const stagingOpSepoliaTestnetAddresses = {
  Governance: "0x577405578695F051CF2C6cfD69c8cA330442Fc82",
  BlockManager: "0x408Fef1A172C58f94D068718c6aaB57C51D92b2E",
  CollectionManager: "0x0995521dDe378B28cEAE48A9fe57e70042B0F7B9",
  StakeManager: "0x6c9702C5640098200C5c7A5660f7fce04F63347a",
  RewardManager: "0x9A64a19F3DCbF358A2D3CB28D5b4B4Ea5331b8de",
  VoteManager: "0x107437c7A0e8ad71e81D2C0589E36297F0248B81",
  Delegator: "0x6f3CabdaC7eaa2Cb686da6E5B6863bdDAD4032ab",
  RAZOR: "0x154fb8A71F431DBd471509ae73Cd5eB63066e3Dd",
  StakedTokenFactory: "0x94f66146FC8F4229339F5E9cf7584d3eE325FB85",
  RandomNoManager: "0x4F145677FF8EB0Ee8aE49480785466531a2Cce1B",
};

export const stagingEuropaTestnetAddresses = {
  Governance: "0x0C91e21D91510A1e95cd3507474c0ac1F620389d",
  BlockManager: "0x1eB1aD4C24cf2CA2027C05a28e78A117fDACD9F9",
  CollectionManager: "0xAFC593871Ab8EE89fd79B2ebd9F1f502268732b9",
  StakeManager: "0x7ce95141358FeF127d3F79f7a5C21C6c2afa1cA3",
  RewardManager: "0xbaa2e7509AdB5762f33064Ad762dF1136184Ef7D",
  VoteManager: "0x57a996015D831F6f4818ff962ffE405b6c6d4B21",
  Delegator: "0x63F82745aBe00c43a71ff2A48E65B281f72Bb54D",
  RAZOR: "0x91d07a181e2Fb787204Ae82B28c69e278C05ac70",
  StakedTokenFactory: "0x047f5865e666DCc278ad3bd8de8503D35d5278Ea",
  RandomNoManager: "0x3A6bbfBBe24F5F5D20F6a7265A0F602d155C2471",
};
